import paypalPlans from '../payment/paypal_plans.json'
import paypalPlansSandbox from '../payment/paypal_plans.sandbox.json'
import stripePrices from '../payment/stripe_prices.json'
import stripePricesSandbox from '../payment/stripe_prices.sandbox.json'

export type Store = 'stripe' | 'paypal'

export type ProductTrackingParams = {
    provider: string
    sku: string
    price: number
    interval: string
    interval_count: number
    trial_sku: string
    trial_price: number
    trial_period_day: number
    currency: string
}

export const getProductTrackingParams = (store: Store, product: PaymentProduct): ProductTrackingParams => {
    return {
        provider: store,
        sku: store === 'stripe' ? product.stripeProduct : product.paypalPlan,
        price: product.productPrice,
        interval: product.productInterval,
        interval_count: product.productIntervalCount,
        trial_sku: store === 'stripe' ? product.stripeTrialProduct : product.paypalPlan,
        trial_price: product.trialPrice,
        trial_period_day: product.trialPeriodDays,
        currency: product.currency,
    }
}

type PayPalBillingPlan = {
    plan_id: string
    name: string
    interval: string
    interval_count: number
    currency: string
    amount: number
    active?: boolean
}

type StripePrice = {
    price_id: string
    type: string
    interval: string
    interval_count: number
    currency: string
    amount: number
    lookup_key: string
    is_cancelation_discount: boolean
}

export type PaymentProduct = {
    currency: string
    stripeProduct: string
    paypalPlan: string
    stripePriceId: string
    stripeTiralPriceId?: string
    productPrice: number
    productInterval: RecurringInterval
    productIntervalCount: number
    stripeTrialProduct?: string
    trialPeriodDays?: number
    trialPrice?: number
}

export type RecurringInterval = 'month'

export type Period = 'quarterly'

const monthsInWeeks = (months: number) => months * 4.34524

const weeksFromRecurringInterval = (interval: RecurringInterval, count: number): number =>
    interval === 'month' ? count : 1

export const calculateWeeklyPrice = (product: PaymentProduct): number =>
    Math.trunc(
        (product.productPrice /
            monthsInWeeks(weeksFromRecurringInterval(product.productInterval, product.productIntervalCount))) *
            100.0,
    ) / 100.0

export const supportedCurrencies = ['USD', 'EUR', 'CHF', 'AUD', 'CAD', 'GBP']
export const fallbackCurrency = 'USD'

export type TrialPrice = {
    duration_days: number
    currency: string
    amount: number
}

export const getStripePrices = (): StripePrice[] => {
    return process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? stripePricesSandbox : stripePrices
}

export const getPayPalBillingPlans = (): PayPalBillingPlan[] => {
    return process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? paypalPlansSandbox : paypalPlans
}

export const getTrialPrices = (currency: string): TrialPrice[] => {
    // We determine the available trial prices from the unique stripe trial prices
    // The trial prices applys for PayPal as well
    const stripePrices = getStripePrices()
    return stripePrices
        .filter(p => p.type === 'one_time' && p.currency === currency)
        .map(price => {
            return {
                duration_days: price.interval_count,
                currency: currency,
                amount: price.amount,
            }
        })
        .sort((a, b) => (a.amount > b.amount ? 1 : -1))
}

// It's necessary to use isActive here when fetching a PayPal plan for a user to subscribe to
// because we may have multiple plans with the same currency and interval count, but different
// prices (and we don't yet know the price of the package we are fetching)
// This flag also allows us to fetch plans that are not active (if needed in the future)
export const getPaymentProduct = ({
    currency,
    trialPrice,
    weightLoss,
    interval,
    isActive,
}: {
    currency: string
    trialPrice?: number
    weightLoss?: number
    interval?: number
    isActive?: boolean
}): PaymentProduct => {
    const hasTrial = !!trialPrice || trialPrice === 0
    const interval_count = weightLoss || weightLoss === 0 ? getIntervalCountForWeightLoss(weightLoss) : interval
    const stripePrice = getStripeRecurringPrice(currency, interval_count)
    const paypalPlan = getPayPalRecurringPlan({ currency, interval_count, hasTrial, isActive })

    if (hasTrial) {
        let stripeTrialPrice = getStripeTrialPrice(currency, trialPrice)
        if (stripeTrialPrice === undefined) {
            stripeTrialPrice = getStripeTrialPrice(currency, 1)
        }
        return {
            currency: currency.toLowerCase(),
            stripeProduct:
                process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_Isrg55O8NF8v6G' : 'prod_J2fgvTnnt1cYeQ',
            stripeTrialProduct:
                process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_IsxGMam5rWiI4C' : 'prod_J2g5amM7I5XHwl',
            paypalPlan: paypalPlan.plan_id,
            stripePriceId: stripePrice.price_id,
            stripeTiralPriceId: stripeTrialPrice.price_id,
            productPrice: stripePrice.amount,
            productInterval: 'month',
            productIntervalCount: interval_count,
            trialPeriodDays: stripeTrialPrice.interval_count,
            trialPrice: stripeTrialPrice.amount,
        }
    } else {
        return {
            currency: currency.toLowerCase(),
            stripeProduct:
                process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_Isrg55O8NF8v6G' : 'prod_J2fgvTnnt1cYeQ',
            paypalPlan: paypalPlan.plan_id,
            stripePriceId: stripePrice.price_id,
            productPrice: stripePrice.amount,
            productInterval: 'month',
            productIntervalCount: interval_count,
        }
    }
}

type ProductEitherSide = null | PaymentProduct[]

export const getPaymentProductsEitherSide = (
    currency: string,
    trialPrice: number,
    weightLoss: number,
): ProductEitherSide => {
    const intervalCount = getIntervalCountForWeightLoss(weightLoss)

    const getTiersAboveIntervalCount = () => {
        switch (intervalCount) {
            case 1:
                return { tierBelowIntervalCount: null, tierAboveIntervalCount: 2 }
            case 2:
                return { tierBelowIntervalCount: 1, tierAboveIntervalCount: 4 }
            case 4:
                return { tierBelowIntervalCount: 2, tierAboveIntervalCount: 6 }
            case 6:
                return { tierBelowIntervalCount: 4, tierAboveIntervalCount: 8 }
            case 8:
                return { tierBelowIntervalCount: 6, tierAboveIntervalCount: 12 }
            case 12:
                return { tierBelowIntervalCount: 8, tierAboveIntervalCount: null }
        }
    }

    const { tierBelowIntervalCount, tierAboveIntervalCount } = getTiersAboveIntervalCount()
    let stripeTrialPrice = getStripeTrialPrice(currency, trialPrice)
    if (stripeTrialPrice === undefined) {
        stripeTrialPrice = getStripeTrialPrice(currency, 1)
    }

    const tierBelowStripePrice = getStripeRecurringPrice(currency, tierBelowIntervalCount)
    const tierBelowPaypalPlan = getPayPalRecurringPlan({ currency, interval_count: tierBelowIntervalCount })
    const tierAboveStripePrice = getStripeRecurringPrice(currency, tierAboveIntervalCount)
    const tierAbovePaypalPlan = getPayPalRecurringPlan({ currency, interval_count: tierAboveIntervalCount })

    const tierBelowPlan = tierBelowIntervalCount
        ? {
              currency: currency.toLowerCase(),
              stripeProduct:
                  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_Isrg55O8NF8v6G' : 'prod_J2fgvTnnt1cYeQ',
              stripeTrialProduct:
                  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_IsxGMam5rWiI4C' : 'prod_J2g5amM7I5XHwl',
              paypalPlan: tierBelowPaypalPlan.plan_id,
              stripePriceId: tierBelowStripePrice.price_id,
              stripeTiralPriceId: stripeTrialPrice.price_id,
              productPrice: tierBelowStripePrice.amount,
              productInterval: 'month' as RecurringInterval,
              productIntervalCount: tierBelowIntervalCount,
              trialPeriodDays: stripeTrialPrice.interval_count,
              trialPrice: stripeTrialPrice.amount,
          }
        : null

    const tierAbovePlan = tierAboveIntervalCount
        ? {
              currency: currency.toLowerCase(),
              stripeProduct:
                  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_Isrg55O8NF8v6G' : 'prod_J2fgvTnnt1cYeQ',
              stripeTrialProduct:
                  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ? 'prod_IsxGMam5rWiI4C' : 'prod_J2g5amM7I5XHwl',
              paypalPlan: tierAbovePaypalPlan.plan_id,
              stripePriceId: tierAboveStripePrice.price_id,
              stripeTiralPriceId: stripeTrialPrice.price_id,
              productPrice: tierAboveStripePrice.amount,
              productInterval: 'month' as RecurringInterval,
              productIntervalCount: tierAboveIntervalCount,
              trialPeriodDays: stripeTrialPrice.interval_count,
              trialPrice: stripeTrialPrice.amount,
          }
        : null

    return [tierBelowPlan, tierAbovePlan]
}

const getStripeTrialPrice = (currency: string, amount: number): StripePrice => {
    const stripePrices = getStripePrices()
    return stripePrices.find(p => p.type === 'one_time' && p.currency === currency && p.amount == amount)
}

export const getStripeRecurringPrice = (
    currency: string,
    interval_count: number,
    isDiscount?: boolean,
): StripePrice => {
    const stripePrices = getStripePrices()
    return stripePrices.find(
        p =>
            p.type === 'recurring' &&
            p.currency === currency &&
            p.interval_count === interval_count &&
            (isDiscount ? p.is_cancelation_discount : !p.is_cancelation_discount),
    )
}

const getPayPalRecurringPlan = ({
    currency,
    interval_count,
    hasTrial = true,
    isActive,
}: {
    currency: string
    interval_count: number
    hasTrial?: boolean
    isActive?: boolean
}): PayPalBillingPlan => {
    const plans = getPayPalBillingPlans()
    if (!hasTrial) {
        return plans.find(
            p =>
                p.currency === currency &&
                p.interval_count === interval_count &&
                p.name.includes('no trial') &&
                (!isActive || (isActive && p.active === true)),
        )
    }

    return plans.find(
        p =>
            p.currency === currency &&
            p.interval_count === interval_count &&
            (!isActive || (isActive && p.active === true)),
    )
}

const getIntervalCountForWeightLoss = (weightLoss: number): number => {
    if (weightLoss <= 4.0) return 1
    if (weightLoss <= 10.0) return 2
    if (weightLoss <= 15.0) return 4
    if (weightLoss <= 20.0) return 6
    if (weightLoss <= 30.0) return 8
    return 12
}
